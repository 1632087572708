<template>
  <card-form :loading="loading">
    <h5>Log de auditoria ao {{ model.classe }} de ID {{ model.primaryKey }}</h5>
    
    <div class="row">
          
      <f-input 
        name="momento"
        :disabled="disabled"
        :value="toStringDatetime(historicoFoco.momento)"
        label="Momento"
        :cols="3"
      />

      <f-input 
        name="usuario"
        :disabled="disabled"
        v-model="historicoFocoEmail"
        label="Usuário"
        :cols="3"
      />

      <f-input 
        name="evento"
        :disabled="disabled"
        v-model="historicoFoco.evento"
        label="Evento"
        :cols="2"
      />

      <f-input 
        name="evento"
        :disabled="disabled"
        v-model="historicoFoco.ipAcesso"
        label="IP de acesso"
        :cols="2"
      />

      <f-input 
        name="paginacao"
        :disabled="disabled"
        v-model="paginacao"
        label="Paginação"
        :cols="2"
      />

    </div>

    <div class="row">
      <div :class="{
        'col-6 col-sm-1 order-2 order-sm-1': !isFirst || !isLast,
        'd-none': isFirst && isLast,
      }">
        <button
          class="btn btn-light w-100 h-100"
          title="Anterior"
          :disabled="isFirst"
          @click="$refs.histSwipeBox.swipetoPrevious()"
        >
          <i class="fas fa-chevron-left"></i>
        </button>
      </div>

      <div :class="{
        'col-12 col-sm-10 order-1 order-sm-2': !isFirst || !isLast,
        'col-12': isFirst && isLast,
      }">
        
        <swipe-box ref="histSwipeBox" @onChange="(idx) => histIdx = idx">
          <div class="historicos" style="height: calc(100vh - 350px)">
            <div v-for="(hist, idx) in model.historicos" :key="hist.id">
              <pre>{{ processarHistorico(idx) }}</pre>
            </div>
          </div>
        </swipe-box>

      </div>

      <div :class="{
        'col-6 col-sm-1 order-3 order-sm-3': !isFirst || !isLast,
        'd-none': isFirst && isLast,
      }">
        <button
          class="btn btn-light w-100 h-100"
          title="Próximo"
          :disabled="isLast"
          @click="$refs.histSwipeBox.swipetoNext()"
        >
          <i class="fas fa-chevron-right"></i>
        </button>
      </div>
    </div>

    <template #footer>
      <button class="btn btn-info px-5" @click="$router.push({name: 'sistema.logAuditoria.lista'})" type="button">Voltar</button>
    </template>

  </card-form>

</template>

<style lang="scss" scoped>
  .historicos {
    width: 100%;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;

    & > * {
      overflow-y: auto;

      & > pre {
        display: inline;
        max-width: 100%;
        width: 100%;
        height: 100%;
        margin: 0 !important;
        padding: 0 !important;
      }
    }
  }

  .btn[disabled] {
    opacity: .25;
  }
</style>

<script>
import CardForm from "@/components/Ui/CardForm";
import SwipeBox from "@/components/swipe-box";
import { getHistorico, ENTIDADE_LOG_ALTERACAO_GUIAS_ID } from "@/services/log-auditoria.service";
import { trataErro } from "@/utils/tratar.retornos";
import { toStringDatetime } from '@/utils/mixins.datetime'

export default {
  components: {
    CardForm,
    SwipeBox,
  },
  data() {
    return {
      toStringDatetime,
      disabled: true,
      loading: false,
      model: {
        classe: '',
        primaryKey: '',
        historicos: [{
          momento: '',
          usuarioEmail: '',
          representanteEmail: '',
          evento: '',
        }],
      },
      histIdx: 0,
    };
  },
  computed: {
    isFirst() {
      return this.histIdx == 0;
    },
    isLast() {
      return this.histIdx == this.model.historicos.length - 1;
    },
    historicoFoco() {
      return this.model.historicos[this.histIdx];
    },
    historicoFocoEmail() {
      if (this.historicoFoco.representanteEmail) {
        return `${this.historicoFoco.representanteEmail} representando ${this.historicoFoco.usuarioEmail}`;
      }
      return this.historicoFoco.usuarioEmail;
    },
    paginacao() {
      return `${this.histIdx + 1} de ${this.model.historicos.length}`;
    },
  },
  mounted() {
    this.disabled = true;//!this.$route.meta.editavel;
    this.loading = true;
    getHistorico(this.$route.params.id, this.$route.params.periodo, this.$route.params.entidadeId)
      .then(resp => {
        this.model = resp.data;

        // Vai até o index do registro pesquisado.
        setTimeout(() => {
          let paramId = this.$route.params.id;
          for (let i = 0; i < this.model.historicos.length; i++) {
            if (this.model.historicos[i].id == paramId) {
              this.$refs.histSwipeBox.goTo(i);
              break;
            }
          }
        }, 250);
      })
      .catch(e => {
        trataErro(e, this);
      })
      .finally(() => {
        this.loading = false;
      })
  },
  methods: {
    processarHistorico(idx) {
      let objFoco = this.model.historicos[idx];
      if (idx == 0 || this.$route.params.entidadeId == ENTIDADE_LOG_ALTERACAO_GUIAS_ID) {
        let res = {
          'Entidade': objFoco.dadosModificados
        };
        if (objFoco.dadosModificadosCombinados && Object.keys(objFoco.dadosModificadosCombinados).length > 0) {
          res['Relacionamentos'] = objFoco.dadosModificadosCombinados;
        }
        return res;
      }

      let histAnt = this.model.historicos[idx - 1].dadosModificados;
      let histFoco = objFoco.dadosModificados;
      console.log('histFoco', objFoco)

      // Exibe apenas modificações dos dados da entidade que foram modificados.
      let modificacoes = {};
      for (let key in histFoco) {
        if (typeof histAnt[key] == 'undefined' || histAnt[key] != histFoco[key]) {
          modificacoes[key] = histFoco[key];
        }
      }

      // Exibe modificações na entidade e nos relacionamentos.
      let res = {};
      if (modificacoes && Object.keys(modificacoes).length > 0) {
        res['Entidade'] = modificacoes;
      }
      if (objFoco.dadosModificadosCombinados && Object.keys(objFoco.dadosModificadosCombinados).length > 0) {
        res['Relacionamentos'] = objFoco.dadosModificadosCombinados;
      }
      return res;
    }
  },
};
</script>
