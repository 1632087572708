<template>
  <card-list>

    <div class="row mt-4">
        <div class="col-sm-12 col-md-10">
          <f-selectsearch 
            label="Pesquisar usuário para adicionar"
            name="seacrh" 
            :cols="4" 
            :searchFunction="pesquisarUsuarioSelect"
            v-authorize="['admConvEditar', 'admConvCriar']"
            @select="addUsuario"></f-selectsearch>
        </div>
    </div>
    <div class="table-responsive">
      <b-table 
          striped hover small 
          :items="items" 
          :fields="colunas">

        <template #table-busy>
          <ui-loading></ui-loading>
        </template>

        <template #cell(dataAdd)="data">
          {{ data.item.dataAdd | dataHora }}
        </template>
        <template #cell(status)="data">
            <UiSwitch
              v-authorize:disable="['admConvEditar', 'admConvCriar']"
              :key="idx"
              v-model="data.item.status"
              @change="(vl) => { changeStatus(data.item, vl) }"
              labelTrue="Sim"
              labelFalse="Não"
            />
        </template>

        <template #cell(action)="data">
          <b-button-group size="sm">
            <b-button
              variant="outline-danger"
              title="remover"
              v-authorize="['admConvEditar', 'admConvCriar']"
              @click="() => excluir(data.item)"
            >
              <i class="uil uil-trash icon-size"></i>
            </b-button>
          </b-button-group>
        </template>

      </b-table>
    </div>

    <template #footer>
      <button class="btn btn-info px-5" @click="voltar()" type="button">Voltar</button>
    </template>


  </card-list>
</template>

<script>
import CardList from "@/components/Ui/CardList";
import UiSwitch from '@/components/Ui/UiSwitch';
import FSelectsearch from '@/components/Form/SelectSearch';

import { getAllUsuarios, pesquisarUsuarioSelect, adicionarUsuarioConvenio, alterarUsuarioConvenio, excluirUsuarioConvenio } from "@/services/convenio.service";
import { trataErro } from "../../../../utils/tratar.retornos";
import { messageSuccess } from "../../../../utils/messages";

export default {
  components: {
    CardList,
    UiSwitch,
    FSelectsearch
  },
  data() {
    return {
      idx: 0,
      pesquisarUsuarioSelect,
      colunas: [
        { key: "usuarioNome", label: "Nome" },
        { key: "status", label: "Ativo", labelTrue: 'Cadastro ativo', labelFalse: 'Cadastro inativo', thStyle: 'width: 140px;' },
        { key: "dataAdd", label: "Adicionado em", thStyle: 'width: 160px;' },
        { key: "action", label: "Ações", thClass: 'text-center', tdClass: 'text-center text-nowrap' },
      ],
      items: [],
      loading: true
    };
  },
  mounted() {
    this.getResults();
  },
  methods: {
    excluir(item) {
        this.$swal.fire({
            title: `Deseja excluir o usuário ${item.usuarioNome} do convênio?`,
            showDenyButton: true,
            confirmButtonText: 'Sim',
            denyButtonText: 'Não',
        }).then(result => {
            if (result.isConfirmed) {
                excluirUsuarioConvenio(item.usuarioId, this.$route.params.id)
                    .then(() => {
                        messageSuccess(this, 'Registro excluído com sucesso.')
                        this.items = this.items.filter(m => m.usuarioId != item.usuarioId)
                    })
                    .catch(error => {
                        trataErro(error, this)
                    })
            } 
            this.idx++;
        })
    },
    changeStatus(item, vl) {
        this.$swal.fire({
            title: `Deseja alterar o status do usuário ${item.usuarioNome}?`,
            showDenyButton: true,
            confirmButtonText: 'Sim',
            denyButtonText: 'Não',
        }).then(result => {
            if (result.isConfirmed) {
                alterarUsuarioConvenio(item.usuarioId, this.$route.params.id, vl)
                    .then(() => {
                        messageSuccess(this, 'Registro alterado com sucesso.')
                    })
                    .catch(error => {
                        trataErro(error, this)
                    })
            } else {
                item.status = !vl;
            }
            this.idx++;
        })
    },
    addUsuario(selected) {
        //chamar api
        adicionarUsuarioConvenio(selected.id, this.$route.params.id)
            .then(({data}) => {
                messageSuccess(this, 'Usuário adicionado ao convênio com sucesso.')
                this.items.unshift(data)
            })
            .catch((error) => {
                trataErro(error, this)
            })
    },
    voltar() {
        if (this.$route.params.backTo) {
            this.$router.push({path: this.$route.params.backTo})
        } else {
            this.$router.push({name: 'sistema.convenio.lista'})
        }
    },

    getResults() {
      this.loading = true;
      getAllUsuarios(this.$route.params.id)
        .then(({ data }) => {
          this.items = data;
        })
        .finally(() => {
          this.loading = false;
        });
    }

  },
};
</script>